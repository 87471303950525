import { Networks } from "./blockchain";

const AVAX_MAINNET = {
    DAO_ADDRESS: "0xf64355CCb38874DBDAAe3d604D306A8bA2c8388a",
    SSB_ADDRESS: "0x769F19A9A449E523fC1F1f7B73051B3bC3C52738",
    WSSB_ADDRESS: "0x31C4c046EFAD4B04b823a919CC0bDd0f663c87d4",
    SB_ADDRESS: "0x5684a087C739A2e845F4AaAaBf4FBd261edc2bE8",
    MIM_ADDRESS: "0x130966628846BFd36ff31a822705796e8cb8C18D",
    STAKING_ADDRESS: "0x3C09c500829D09AfEA7575005fcAFBacFcde6902",
    STAKING_HELPER_ADDRESS: "0x49Ec4D4d5051265E5259f6dE78c086e78Def9621",
    SB_BONDING_CALC_ADDRESS: "0xC28CfF5a18eFd8e6B5c319df4D9C31D3Abe6b71A",
    TREASURY_ADDRESS: "0x1e63a2eB2827db56d3CB1e1FF17ef1040B2d3D3f",
    ZAPIN_ADDRESS: "0xc669dC61aF974FdF50758d95306e4083D36f1430",
    REDEEM_ADDRESS: "0x921E40DbFdA4B3C7339f6F7D197e2C021160eC8b",
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.AVAX) return AVAX_MAINNET;

    throw Error("Network don't support");
};
